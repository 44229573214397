/**
 * @flow
 */
import React from 'react';
import FormManager from '../../../lib/FormManager';
import {useSelector} from "react-redux";
import {FD, FN} from "../../field-defs/newCoship";

class CourierHouseBLWorkDetailsFormManager extends FormManager {
  isShowForm;
  constructor() {
    super({
      prefix: `courier-house-work_details-form`,
      fields: [
        FD.VSL_VOY, FD.CONTAINER_SEQ, FD.POL, FD.ETD, FD.POD, FD.ETA,
        FD.CARRIER_ID, FD.CARRIER, FD.CRAFT_ID, FD.FLIGHT_NO,
        FD.DEPARTURE, FD.DESTINATION, FD.ARRIVAL_DATE, FD.COMMODITY, FD.SVC_TERM1, FD.SVC_TERM2,
        {name: 'containerNo', serverName: 'containerNo', label: 'CNTR NO.', smallMargin: true},
      ],
      formProps: {
        horizontal: true,
        doNotUseButtons: true,
        doNotUseCard: true,
      },
    });
  };
  onRender = () => {
    const {renderField: _r, getField: _f, setValue: _c, getValue: _v, _n} = this;
    const {carrierType, flightData} = useSelector(state => state['newCoship']);
    _f(FN.VSL_VOY).disabled = true;
    _f(FN.CONTAINER_SEQ).disabled = true;
    _f('containerNo').disabled = true;
    _f(FN.POL).disabled = true;
    _f(FN.POD).disabled = true;
    _f(FN.ETD).disabled = true;
    _f(FN.ETA).disabled = true;
    _f(FN.FLIGHT_NO).disabled = true;
    _f(FN.DEPARTURE).disabled = true;
    _f(FN.DESTINATION).disabled = true;
    _f(FN.COMMODITY).type = 'text';
    _f(FN.COMMODITY).defaultValue = 'USED HOUSEHOLD GOODS AND PERSONAL EFFECTS';
    _f(FN.SVC_TERM1).defaultValue = 'DR';
    _f(FN.SVC_TERM2).defaultValue = 'DR';

    return (
      <div style={{width: 'calc(100% + 8px)'}}>
        {this.isShowForm &&
          <div className={'pl-8 pr-20 pt-12 pb-1'}>
            {carrierType === 'O' && <OCEAN _r={_r} _c={_c} _v={_v}/>}
            {carrierType === 'A' && <AIR _r={_r} _c={_c} flightData={flightData}/>}
            {carrierType === 'G' && <GROUND _r={_r}/>}
          </div>
        }
      </div>
    );
  };
  onValidate = (values) => {};
}

const OCEAN = (props) => {
  const {_r, _c, _v} = props;
  React.useEffect(() => {
    _c(FN.COMMODITY, _v(FN.COMMODITY) ?? 'USED HOUSEHOLD GOODS AND PERSONAL EFFECTS');
    _c(FN.SVC_TERM1, _v(FN.SVC_TERM1) ?? 'DR');
    _c(FN.SVC_TERM2, _v(FN.SVC_TERM2) ?? 'DR');
  }, []);
  return (
    <>
      <div className={'flex w-full'}>
        <div className={'flex-1 mr-2'}>{_r(FN.VSL_VOY)}</div>
        <div className={'flex-1 mr-2'}>{_r(FN.POL)}</div>
        <div className={'flex-1 mr-2'}>{_r(FN.POD)}</div>
        <div className={'flex-1'}>{_r(FN.CONTAINER_SEQ)}</div>
      </div>
      <div className={'flex w-full'}>
        <div className={'flex-1 mr-2'}>{_r('containerNo')}</div>
        <div className={'flex flex-1 mr-2'}>
          <div className={'flex-2 mr-2'}>{_r(FN.SVC_TERM1)}</div>
          <div className={'flex-1'}>{_r(FN.SVC_TERM2)}</div>
        </div>
        <div className={'flex-1 mr-2'}>{''}</div>
        <div className={'flex-1'}>{''}</div>
      </div>
      <div className={'flex w-full'}>
        <div className={'flex-1 mr-2'}>{_r(FN.COMMODITY)}</div>
      </div>
    </>
  );
};

const AIR = (props) => {
  const {_r, _c, flightData} = props;
  React.useEffect(() => {
    _c(FN.FLIGHT_NO, flightData?.flightNo ?? undefined);
    _c(FN.ARRIVAL_DATE, flightData?.arrivalDate ?? undefined);
    _c(FN.DEPARTURE, flightData?.departure ?? undefined);
    _c(FN.DESTINATION, flightData?.destination ?? undefined);
  }, [flightData]);
  return (
    <>
      <div className={'flex w-full'}>
        <div className={'flex-1 mr-2'}>{_r(FN.FLIGHT_NO)}</div>
        <div className={'flex-1 mr-2'}>{_r(FN.ARRIVAL_DATE)}</div>
        <div className={'flex-1 mr-2'}>{_r(FN.DEPARTURE)}</div>
        <div className={'flex-1'}>{_r(FN.DESTINATION)}</div>
      </div>
    </>
  );
};

const GROUND = (props) => {
  const {_r} = props;
  return (
    <>
      <div className={'flex w-full'}>
        <div className={'flex-1 mr-2'}>{_r(FN.POL)}</div>
        <div className={'flex-1 mr-2'}>{_r(FN.ETD)}</div>
        <div className={'flex-1 mr-2'}>{_r(FN.POD)}</div>
        <div className={'flex-1'}>{_r(FN.ETA)}</div>
      </div>
    </>
  );
};

export default CourierHouseBLWorkDetailsFormManager;
