/**
 * @flow
 */
import React from 'react';
import moment from 'moment';
import type {DataGridInfo} from '../redux/reducers/dataGridReducer';
import queryString from 'query-string';
import {showDialog} from '../redux/reducers/globalDialogReducer';
import {util} from './service';
import {
  courierWorkOptions,
  eCommerceWorkOptions,
  forwardingWorkOptions,
  movingWorkOptions,
  teamMain
} from '../forms/helper';
import {CARGO_COURIER, CARGO_ECOMMERCE, CARGO_FORWARDING, CARGO_MOVING} from '../modal-tabs/types';
import FormManager from '../lib/FormManager';
import createNumberMask from 'text-mask-addons/dist/createNumberMask'

// const REGEX_THOUSAND_SEP = /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g;

const numberMask = createNumberMask({
  prefix: '',
  thousandsSeparatorSymbol: '',
  allowDecimal: true,
})
const numberNoDecimalMask = createNumberMask({
  prefix: '',
  thousandsSeparatorSymbol: '',
  allowDecimal: false,
})
const numberDecimalThreeMask = createNumberMask({
  prefix: '',
  thousandsSeparatorSymbol: '',
  allowDecimal: true,
  decimalLimit: 3,
})
const numberDecimal4Mask = createNumberMask({
  prefix: '',
  thousandsSeparatorSymbol: '',
  allowDecimal: true,
  decimalLimit: 4,
})
const numberDecimal6Mask = createNumberMask({
  prefix: '',
  thousandsSeparatorSymbol: '',
  allowDecimal: true,
  decimalLimit: 6,
})
const numberMaskWithMinus = createNumberMask({
  prefix: '',
  thousandsSeparatorSymbol: '',
  allowDecimal: true,
  allowNegative: true,
})

class Util {
  getState;
  dispatch;
  history;
  location;
  gridInfoCache = {};
  MASK_DATE = [/[12]/, /\d/, /\d/, /\d/, '-' , /[01]/, /\d/, '-', /[0123]/, /\d/];
  MASK_TIME = [/\d/, /\d/, ':', /\d/, /\d/];
  MASK_PHONE;
  MASK_RATE = ['0', '.', /\d/, /\d/];
  MASK_COUNTRY = [/[aA-zZ]/,/[aA-zZ]/];
  MASK_NUMBER = numberMask;
  MASK_NUMBER_NO_DECIMAL = numberNoDecimalMask;
  MASK_NUMBER_DECIMAL_THREE = numberDecimalThreeMask;
  MASK_NUMBER_DECIMAL_FOUR= numberDecimal4Mask;
  MASK_NUMBER_DECIMAL_SIX= numberDecimal6Mask;
  MASK_NUMBER_WITH_MINUS = numberMaskWithMinus;
  constructor() {
    const regexPhone = /[+\-\d]/;
    const maskPhone = [];
    for (let i = 0; i < 20; ++i) {
      maskPhone.push(regexPhone);
    }
    this.MASK_PHONE = maskPhone;
  }
  sendLoginPage() {
    if (this.history !== undefined ) {
      this.history.push('/admin/login');
    }
  }
  getToken() {
    if (typeof window !== 'undefined') {
      return localStorage.getItem('@token');
    }
  }
  isDateStr(str) {
    let date = moment(str, 'YYYY-MM-DD', true);
    return date.isValid();
  }

  formatDate(ts, format = 'YYYY-MM-DD HH:mm:ss') {
    if (!ts || ts === 0) return '';
    return moment(ts).format(format);
  }
  formatD(ts) {
    return ts ? moment(ts).format('YYYY-MM-DD') : '';
  }
  formatDTS(ts) {
    return ts ? moment(ts).format('YYYY-MM-DD HH:mm') : '';
  }
  formatDT(ts) {
    return ts ? moment(ts).format('YYYY-MM-DD HH:mm:ss') : '';
  }
  dateFormat(str) {
    return str ? moment(str).format('MMMM DD, YYYY') : '';
  }
  dateFormatS(str) {
    return str ? moment(str).format('MMM. DD, YYYY') : '';
  }
  formatFlightT(ts) {
    return ts ? moment(ts).format('HH:mm') : '';
  }
  formatYesNo(p) {
    return p.value === true ? 'Yes' : 'No';
  }
  formatCBM({value}) {
    if (typeof value === 'string') {
      value = parseFloat(value);
      if (isNaN(value)) {
        return '';
      }
    }
    // return value?.toFixed(2).replace(REGEX_THOUSAND_SEP, ',') ?? '';
    return value?.toLocaleString(undefined, {minimumFractionDigits: 3, maximumFractionDigits: 3}) ?? '';
  }
  formatWeight({value}) {
    if (typeof value === 'string') {
      value = parseFloat(value);
      if (isNaN(value)) {
        return '';
      }
    }
    if (typeof value === 'number') {
      value = parseFloat(value);
    }
    // return value?.toFixed(1).replace(REGEX_THOUSAND_SEP, ',') ?? '';
    return value?.toLocaleString(undefined, {minimumFractionDigits: 1, maximumFractionDigits: 1}) ?? '';
  }
  formatWeightFromString(p) {
    return this.formatWeight({value: this.toFloat(p)});
  }
  formatCBMFromString(p) {
    return this.formatCBM({value: this.toFloat(p)});
  }
  dateFormatter(p) {
    return p.value ? moment(p.value).format('YYYY-MM-DD') : '';
  }
  dateTimeFormatter(p) {
    return p.value ? moment(p.value).format('YYYY-MM-DD HH:mm:ss') : '';
  }
  dateTimeFlightFormatter(p) {
    return p.value ? moment(p.value).format('YYYY-MM-DD HH:mm') : '';
  }
  dateFormatterString(p) {
    return p.value ? p.value.split(' ')[0] : '';
  }
  getSpecificDate(str) {
    return moment(str, "YYYYMMDD");
  }
  toEndDateTS(date) {
    if (date instanceof Date) return date.getTime();
    if (date === null || date === undefined) return undefined;
    return moment(date).endOf('day').toDate().getTime();
  };
  yesNoFormatter(p) {
    return p.value === true ? 'Yes' : 'No';
  }
  currencyFormatter(p) {
    // const str = p.value?.toString().replace(REGEX_THOUSAND_SEP, ',') ?? '';
    const value = parseFloat(p.value);
    if (isNaN(value)) return '';
    const str = value?.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 4});
    if (!str) return '';
    if (str.indexOf('.') < 0) {
      return str + '.00';
    } else {
      return str;
    }
  }
  currencyFormatterEx(param, currencyFieldName) {
    // let value = param.value?.toString().replace(REGEX_THOUSAND_SEP, ',');
    // if (!value) return value;
    let value = parseFloat(param.value);
    if (isNaN(value)) return '';
    value = value.toLocaleString(undefined, {minimumFractionDigits: 2});
    const currency = param.data[currencyFieldName];
    if (value.indexOf('.') < 0 && currency !== 'KRW') { // 한화인 경우 소수점이하 두자리를 보여주지 않음
      value += '.00';
    }
    return currency ? `${currency} ${value}` : value;
  }
  currencyFormatterEx2(p, fractionDigits) {
    const value = parseFloat(p.value);
    if (isNaN(value)) return '';
    const str = fractionDigits ? (value?.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: fractionDigits})) : value + '';
    if (!str) return '';
    if (str.indexOf('.') < 0) {
      return str + '.00';
    } else {
      return str;
    }
  }
  currencyFormat(number: number) {
    const num = parseInt(number).toString();
    const numLen = parseInt(Math.abs(number)).toString().length;
    let result = 0;
    if(parseInt(numLen) >= 4 && parseInt(numLen) <= 6) {
      result = `${(parseInt(num)/1000).toFixed(1)}K`;
    } else if(parseInt(numLen) >= 7 && parseInt(numLen) <= 9) {
      result = `${(parseInt(num)/1000000).toFixed(1)}M`;
    } else if(parseInt(numLen) >= 10 && parseInt(numLen) <= 12) {
      result = `${(parseInt(num)/1000000000).toFixed(1)}B`;
    } else if(parseInt(numLen) >= 13 && parseInt(numLen) <= 15) {
      result = `${(parseInt(num)/1000000000000).toFixed(1)}T`;
    } else {
      result = `${parseInt(num)}.00`;
    }
    return result;
  };
  formatCurrency(number: number, currency: string = 'CAD', fractionDigits) {
    if (currency === 'KRW') {
      return number?.toLocaleString() ?? '';
    } else {
      if (fractionDigits) {
        return number?.toLocaleString(undefined,{minimumFractionDigits: 2, maximumFractionDigits: fractionDigits}) ?? '';
      } else {
        return number?.toLocaleString(undefined,{minimumFractionDigits: 2}) ?? '';
      }
    }
    // return currency === 'KRW' ? number?.toLocaleString() ?? ''
    //                           : number?.toLocaleString(undefined,{minimumFractionDigits: 2}) ?? '';
    // return (number === undefined || number === null) ? number : number.toFixed(2).replace(REGEX_THOUSAND_SEP, ',');
  }
  destinationFormatter(param) {
    const {dcount = 0} = param.data;
    const {value} = param;
    if (!value) return '';
    return dcount > 1 ? `${param.value} (+${dcount - 1})` : param.value;
  }
  toTS(date) {
    if (date instanceof Date) return date.getTime();
    if (date === null || date === undefined) return undefined;
    return moment(date).toDate().getTime();
  }
  toStartTS(date, mode: 'day' | 'week' | 'month') {
    if (date instanceof Date) return date.getTime();
    if (date === null || date === undefined) return undefined;
    return moment(date).startOf(mode).toDate().getTime();
  }
  toEndTS(date, mode: 'day' | 'week' | 'month') {
    if (date instanceof Date) return date.getTime();
    if (date === null || date === undefined) return undefined;
    return moment(date).endOf(mode).toDate().getTime();
  }
  toDefaultFirstTS(date = this.getCurrentDate(), mode: 'day' | 'week' | 'month') {
    if (date instanceof Date) return date.getTime();
    if (date === null || date === undefined) return undefined;
    return moment().subtract(12, 'months').startOf('month').toDate().getTime();
  }
  toDefaultEndTS(date = this.getCurrentDate(), mode: 'day' | 'week' | 'month') {
    if (date instanceof Date) return date.getTime();
    if (date === null || date === undefined) return undefined;
    return moment().add(1, 'year').endOf('year').toDate().getTime();
  }
  toInt(value: any) {
    return typeof value === 'string' ? parseInt(value) : value;
  }
  toFloat(value: any) {
    return typeof value === 'string' ? parseFloat(value) : value;
  }
  twoDecimalPlaceFormatter(p) {
    // const str = p.value?.toFixed(2).toString().replace(REGEX_THOUSAND_SEP, ',') ?? '';
    const value = parseFloat(p.value);
    if (isNaN(value)) return '';
    const str = value?.toLocaleString(undefined, {minimumFractionDigits: 2});
    if (!str) return '';
    if (str.indexOf('.') < 0) {
      return str + '.00';
    } else {
      return str;
    }
  }
  nav(path) {
    this.history.push(path);
  }
  openTab(path) {
    window.open(path, '_blank');
  }
  valuesFromListData(row, fields, names, dateFields: string[]) {
    const values = {};
    for(const field of fields) {
      const nameMap = names.find(([, name]) => field.name === name);
      const listName = nameMap[0];
      if (dateFields.includes(field.name)) {
        if (row[listName] === 0) {
          values[field.name] = '';
        } else {
          values[field.name] = this.formatDate(row[listName], 'YYYY-MM-DD');
        }
      } else {
        values[field.name] = row[listName];
      }
    }
    return values;
  }
  setGridInfo(name, info) {
    this.gridInfoCache[name] = {...this.gridInfoCache[name], ...info};
  }
  getGridInfo(name): DataGridInfo {
    return this.gridInfoCache[name];
  }
  showDialog(message, title = 'Success', color = 'success') {
    showDialog(message, title, color);
  }
  showSuccess(message) {
    showDialog(message, 'Success', 'success');
  }
  showWarning(message) {
    showDialog(message, 'Warning', 'warning');
  }
  showError(message = 'System Error...') {
    showDialog(message, 'System Error', 'danger');
  }
  showConfirm(message, onConfirm, onCancel, okText, cancelText) {
    showDialog(message, 'Confirm', 'primary', onConfirm, onCancel, okText, cancelText);
  }
  getPaginationInfo(gridInfo: DataGridInfo) {
    const {page = 1, rowCount = 20, totalCount = 0} = gridInfo;
    if(totalCount === 0) {
      return null;
    }
    const startIndex = (page - 1) * rowCount;
    let endIndex = page * rowCount - 1;
    if(endIndex >= totalCount) endIndex = totalCount - 1;
    let totalPages;
    if(totalCount <= rowCount) {
      totalPages = 1;
    } else {
      if(rowCount % totalCount === 0) {
        totalPages = parseInt(totalCount / rowCount);
      } else {
        totalPages = parseInt(totalCount / rowCount) + 1;
      }
    }
    return {page, totalPages, totalCount, startIndex, endIndex};
  }
  noop() {}
  getBeginEndDateTS(date, mode: 'day' | 'week' | 'month') {
    // const startDate = moment(date).startOf(mode).set({hour:0,minute:0,second:0,millisecond:0}).toDate().getTime();
    // const endDate = moment(date).endOf(mode).set({hour:0,minute:0,second:0,millisecond:0}).toDate().getTime();
    const startDate = moment(date).startOf(mode).toDate().getTime();
    const endDate = moment(date).endOf(mode).toDate().getTime();
    return [startDate, endDate];
  }
  canDeleteSchedule(ts: number) {
    // 하루전 스케줄만 삭제할수 있음!
    const day = moment(ts).format('YYYY-MM-DD');
    const yesterday = moment().subtract(1, 'day').format('YYYY-MM-DD');
    return day >= yesterday;
  }
  renderFooter(footer, className = 'moving-form-footer') {
    const labels = [];
    const [c_ts = 0, c_name] = footer?.create ?? [];
    const [e_ts = 0, e_name] = footer?.edit ?? [];
    const print = footer?.print ?? '';
    if (c_ts > 0) {
      labels.push(`Created by ${c_name} (${util.formatDate(c_ts)})`);
    }
    if (e_ts > 0) {
      labels.push(`Edited by ${e_name} (${util.formatDate(e_ts)})`);
    }
    if (print) {
      labels.push(`Print: ${print}`);
    }
    if (labels.length === 1) {
      return (
        <div className={className}>
          {labels[0]}
        </div>
      );
    } else if (labels.length === 2) {
      return (
        <div className={className}>
          {labels[0]}
          <br />
          {labels[1]}
        </div>
      );
    } else if (labels.length === 3) {
      return (
        <div className={className}>
          {labels[0]}
          <br />
          {labels[1]}
          <br />
          {labels[2]}
        </div>
      );
    } else {
      return null;
    }
  }
  isFormikChecked(value) {
    if (Array.isArray(value)) {
      return value?.[0] === 'on';
    } else {
      return value === true;
    }
  }
  workTypeMap = {P: 'O', D: 'I', R: 'R'};
  buildEvents(data: Object[]) {
    return data.map(item => {
      const {hbl, cbm, customer_name: name, job_city: city, job_date: date, job_time: time, work_type: workType, work_count: workCount} = item;
      const type = workType.length === 2 ? workType[1] : '';
      let title = `${this.workTypeMap[type]} ${cbm} ${name} ${time ? time : ''} ${city ? city : ''}`;
      if (workCount > 1) {
        title += `(${workCount})`;
      }
      return {
        title,
        start: new Date(date),
        end: new Date(date),
        allDay: true,
        resource: {hbl, cbm, name, city, date, time, workType, workCount},
      };
    });
  }
  workOptionsMap = {
    [CARGO_MOVING]: movingWorkOptions,
    [CARGO_COURIER]: courierWorkOptions,
    [CARGO_ECOMMERCE]: eCommerceWorkOptions,
    [CARGO_FORWARDING]: forwardingWorkOptions,
  };
  workOptionsPrefixMap = {
    'Moving': 'M',
    'Courier': 'C',
    'Forwarding': 'F',
    'eCommerce': 'E',
  };
  getPickupDate(partnerId, dropzoneOptions: Object[]) {
    const dropzone = dropzoneOptions.find(i => i.value === partnerId);
    const {pickupDay, pickupTime} = dropzone ?? {};
    if (pickupDay && pickupTime) {
      const daysStart = {
        Sun: 0, Mon: 1, Tue: 2, Wed: 3, Thu: 4, Fri: 5, Sat: 6,
      };
      const daysInfo = [
        ['Sun', false, ''],
        ['Mon', false, ''],
        ['Tue', false, ''],
        ['Wed', false, ''],
        ['Thu', false, ''],
        ['Fri', false, ''],
        ['Sat', false, ''],
        ['Sun', false, ''],
        ['Mon', false, ''],
        ['Tue', false, ''],
        ['Wed', false, ''],
        ['Thu', false, ''],
        ['Fri', false, ''],
        ['Sat', false, ''],
      ];
      let startDate = moment().startOf('week');
      daysInfo.forEach((dayInfo, index) => {
        const [day, allowed] = dayInfo;
        if (pickupDay.find(d => d === day)) {
          daysInfo[index][1] = true;
        }
        daysInfo[index][2] = startDate.format('YYYY-MM-DD');
        startDate = startDate.add(1, 'day');
      });
      const todayDay = moment().format('ddd');
      const todayDate = moment().format('YYYY-MM-DD');
      const todayTime = moment().format('A');
      const todayIndex = daysStart[todayDay];
      let pickupDayInfo;
      for (let i = todayIndex; i < daysInfo.length; ++i) {
        const [day, allowed, date] = daysInfo[i];
        if (allowed) {
          if (date === todayDate) {
            if (pickupTime === 'AM' && todayTime === 'PM') {
              //console.log('hello same day', day, allowed, date, todayDate);
              pickupDayInfo = {pickupDate: moment(date).toDate(), pickupTime: todayTime};
              break;
            }
          } else {
            //console.log('hello diff day', day, allowed, date, todayDate);
            pickupDayInfo = {pickupDate: moment(date).toDate(), pickupTime: todayTime};
            break;
          }
        }
      }
      return pickupDayInfo;
    }
  }
  logFields(FN, LB) {
    let output = '';
    for(const key of Object.keys(FN)) {
      output += `{name: FN.${key}, serverName: FN.${key}, label: '${LB?.[key] ?? ''}'},\n`;
    }
    console.log(output);
  }
  getQS() {
    return queryString.parse(document.location.search);
  }
  addDays(ts: number, amount: number): string { // 타임스탬프를 받아서 문자열(YYYY-MM-DD)로 반환함
    const m = moment(ts);
    return m.add(amount, 'days').format('YYYY-MM-DD');
  }
  diffDays(date1: string, date2: string) {
    if (!date1 || !date2) return 0;
    const d1 = moment(date1);
    const d2 = moment(date2);
    return d2.diff(d1, 'days');
  }
  getAddDays(amount, date?: string) {
    if (date) {
      return moment(date).add(amount, 'days').format('YYYY-MM-DD');
    } else {
      return moment().add(amount, 'days').format('YYYY-MM-DD');
    }
  }
  getSubtractDays(amount: number, date?: string) {
    if (date) {
      return moment(date).subtract(amount, 'days').format('YYYY-MM-DD');
    } else {
      return moment().subtract(amount, 'days').format('YYYY-MM-DD');
    }
  }
  getAddMonths(amount, date?: string) {
    if (date) {
      return moment(date).add(amount, 'months').format('YYYY-MM-DD');
    } else {
      return moment().add(amount, 'months').format('YYYY-MM-DD');
    }
  }
  getYearFromDays(days: number) {
    return parseInt(days / 365);
  }

  // check valid date
  isValidDate(ts: number) {
    const m = moment(ts);
    return m.isValid();
  }

  getCurrentDate() {
    const m = moment();
    return m.format('YYYY-MM-DD');
  }

  getCurrentDate2() {
    const m = moment();
    return m.format('YYYY.MM.DD');
  }

  getCurrentYear() {
    const m = moment();
    return m.format('YYYY');
  }

  getCurrentMonth() {
    const m = moment();
    return m.format('MM');
  }

  getCurrentDay() {
    const m = moment();
    return m.format('DD');
  }

  getCurrentDateTS() {
    return moment().valueOf();
  }

  getStartDateMonth() {
    return moment().startOf('month').toDate();
  }

  getRemoveSpace(str) {
    return str.replace(/(\s*)/g, "");
  }

  blMasterLinkFormatter(param) {
    return (
      <div>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a href={'#'} style={{color: '#000000', textDecorationLine: 'underline'}} onClick={(e) => {
          e.preventDefault();
          // util.nav(`/admin/bl/master/${param.data['bl_id']}`);
          util.openTab(`/admin/${param.data['kind_str']}/master/mbl?id=${param.data['bl_id']}`);
        }}>
          {param.value}
        </a>
      </div>
    );
  }

  blHouseLinkFormatter(param, isOpenTab = false) {
    const blId = param.data['bl_id'] ?? param.data['id'];
    return (
      <div>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a href={'#'} style={{color: '#000000', textDecorationLine: 'underline'}} onClick={(e) => {
          e.preventDefault();
          if(isOpenTab) {
            // util.openTab(`/admin/bl/house/${blId}`);
            util.openTab(`/admin/${param.data['kind_str']}/house/customer?id=${blId}`);
          } else {
            // util.nav(`/admin/bl/house/${blId}`);
            util.nav(`/admin/${param.data['kind_str']}/house/customer?id=${blId}`);
          }
        }}>
          {param.value}
        </a>
      </div>
    );
  }

  accountLinkFormatter(param, type, isOpenTab = false) {
    const accountId = param.data['account_id'] ?? param.data['id'];
    const accType = param.data['acc_type'] ?? type;
    return (
      <div>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a href={'#'} style={{color: '#000000', textDecorationLine: 'underline'}} onClick={(e) => {
          e.preventDefault();
          if(isOpenTab) {
            util.openTab(`/admin/account/list/${accType.toLowerCase()}?id=${accountId}`);
          } else {
            util.nav(`/admin/account/list/${accType.toLowerCase()}?id=${accountId}`);
          }
        }}>
          {param.value}
        </a>
      </div>
    );
  }

  isValidPermitForAccount(user, mode) {
    if(!(user.isManager || user.team_main === teamMain.ACCOUNT) && mode === 'edit') {
      return false;
    }
    return true;
  }

  /**
   * @param keys 아래 데이터 객체에서 복사할 키 배열
   * @param data 데이터 객체
   * @param targetForm 해당키의 데이터를 복사할 폼
   * @example
   * const jshipment = {...}; // API call to fetch...
   * const keys = [
   *   FN.GROSS_WEIGHT_KG,
   *   FN.CBM, FN.COMMODITY,
   *   FN.PACKAGE,
   *   FN.PACKAGE_TYPE,
   *   [FN.RATE, FN.RATE_1], // 마스터키와 하우스키가 다른 경우 [마스터키, 하우스키] 쌍을 전달함
   *   ...
   * ];
   * util.copyFromKeys(keys, jshipment, blForm);
   */
  copyFromKeys(keys: Array, data: Object, targetForm: FormManager) {
    for (const key of keys) {
      if (Array.isArray(key)) {
        const [masterKey, houseKey] = key;
        if (data[masterKey]) {
          targetForm.setValue(houseKey, data[masterKey]);
        }
      } else {
        if (data[key]) {
          targetForm.setValue(key, data[key]);
        }
      }
    }
  }
  weightForPrint(valueInKG, unit = 'KG', addUnitLabel = true) {
    if(valueInKG) {
      valueInKG = parseFloat(valueInKG);
      valueInKG = (unit === 'LB' ? valueInKG * 2.20462 : valueInKG).toFixed(2);
      return addUnitLabel ? `${valueInKG} ${unit}` : valueInKG;
    } else {
      return undefined;
    }
  }
  volumeForPrint(valueInCBM, unit = 'KG', addUnitLabel = true) {
    if(valueInCBM) {
      valueInCBM = parseFloat(valueInCBM);
      valueInCBM = (unit === 'LB' ? valueInCBM * 35.3147 : valueInCBM).toFixed(3);
      return addUnitLabel ? `${valueInCBM} ${cbmMap[unit]}` : valueInCBM;
    } else {
      return undefined;
    }
  }
  numberToWords(num) {
    if (num === 0) return "ZERO";
    else return convert_millions(num).toUpperCase();
  }
  labelFromOptions(value, options) {
    if (!value) {
      return options[0]['label'];
    }
    return options.find(i => i.value === value)?.label ?? options[0].label;
  }

  svcTermFromOptions(value, options) {
    if (!value) {
      return "DOOR";
    }
    return options.find(i => i.value === value)?.label ?? "DOOR";
  }

  displayWithMark(data1, data2, mark) {
    return (data1 || data2) ? `${data1??''} ${mark} ${data2??''}` : '';
  };

  getRangeYears = () => {
    const years = [];
    const dateStart = moment().year('2018');
    const dateEnd = moment().add(1, 'year');
    while (dateEnd.diff(dateStart, 'years') >= 0) {
      years.push(dateStart.format('YYYY'))
      dateStart.add(1, 'year')
    }
    return years;
  };
  getMonths = () => {
    return [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    ];
  };
  round(value, decimals) {
      return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals);
  }
  chunk = (arr, size) => {
    let i, j, temp = [], chunk = size;
    if(arr?.length > 0) {
      for (i = 0, j = arr?.length; i < j; i += chunk) {
          temp.push(arr.slice(i, i + chunk));
      }
    }
    return temp;
  }
  replaceAt(str, index, chr) {
    const strLen = str.length;
    if(index > strLen-1) return str;
    return str.substr(0, index) + chr + (strLen < 4 ? str.substr(index+1) : str.substr(index+chr.length));
  }
  uppercaseFormatter(p) {
    return p.value.toUpperCase();
  }
  textSubstring = (data, len) => {
		return data.length > len ? `${data.substr(0, len)}...` : data;
	}
  addRowIdToList = (list) => {
    return list?.map((data, index) => ({...data, rowId: index + 1})) ?? [];
  };
  arraySum(arr: Array<number>) {
    return arr?.reduce((acc: number, cur: number) => acc + cur, 0) || 0;
  }
  getOptionLabel(options: Array<object>, key: number | string) {
    return options.find(x => x.value === key).label;
  }
}

const ones = ['', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine'];
const tens = ['', '', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'];
const teens = ['ten', 'eleven', 'twelve', 'thirteen', 'fourteen', 'fifteen', 'sixteen', 'seventeen', 'eighteen', 'nineteen'];

function convert_millions(num) {
  if (num >= 1000000) {
    return convert_millions(Math.floor(num / 1000000)) + " million " + convert_thousands(num % 1000000);
  } else {
    return convert_thousands(num);
  }
}

function convert_thousands(num) {
  if (num >= 1000) {
    return convert_hundreds(Math.floor(num / 1000)) + " thousand " + convert_hundreds(num % 1000);
  } else {
    return convert_hundreds(num);
  }
}

function convert_hundreds(num) {
  if (num > 99) {
    return ones[Math.floor(num / 100)] + " hundred " + convert_tens(num % 100);
  } else {
    return convert_tens(num);
  }
}

function convert_tens(num) {
  if (num < 10) return ones[num];
  else if (num >= 10 && num < 20) return teens[num - 10];
  else {
    return tens[Math.floor(num / 10)] + " " + ones[num % 10];
  }
}

const cbmMap = {
  KG: 'CBM',
  LB: 'CFT',
};

export default Util;
