/**
 * @flow
 */
import React from 'react';
import {util} from '../services/service';
import {oemPrintTitleOptions} from '../forms/print/v2/OEMBLPrintForm';
import {freightTypeOptions, orgBlTypeOptions, svcTypeOptions, blPrintTitleOptions} from '../forms/field-defs/bl';
import copyUrl from '../media/copy.png';
import draftUrl from '../media/draft.png';
import originalUrl from '../media/original.png';
import seawaybillUrl from '../media/seawaybill.png';
import textReleaseUrl from '../media/text-release.png';
import coshipLogo from '../media/logo_coship.jpg';
import lmsLogo from '../media/logo_lms.jpg';
import bankUrl from '../media/bank.png';

class OEHPrint extends React.Component {
  render() {
    const printData = this.getPrintData();
    if (!printData) {
      return null;
    }
    const {isOriginal} = printData;
    if (isOriginal) {
      return (
        <div>
          {[0, 1, 2, 3, 4, 5, 6].map(i => this.renderPage(printData, i))}
        </div>
      );
    } else {
      return this.renderPage(printData);
    }
  }
  renderPage(printData, index) {
    const {
      titleLabel, shipperAddr, shipperAddrEng, consigneeAddr, consigneeAddrLocal, forwardingAgentAddr, notifyAddr, exportRefNo, forwardingAgent,
      bkgNo, filingNo, filing_no, b13No,
      carrier, vslVoy, onboard, etd, eta,
      polLabel, podLabel, porLabel,
      said, say, desc, mark, remark, descOption, unit, forTrTo, onwardRoute,
      freightLabel, orgBlTypeLabel, svcTerm1Label, svcTerm2Label, packCount, packType, grossWeightKg, cbm,
      containers,
      blankLines,
      company, address, email, fax, phone, pic,
      title, blNo, blPrintOpt, releaseType, showVesselPol, showOnboardDate, showPkgs, isOriginal,
      emptyPickup, forwardAgent, partner, masterConsignee,
      doc, pickupDate, portOpen, rail, vgm,
      delivery, final, podFull, polFull, porFull,
    } = printData;
    const {renderCell: _cell, renderCell2: _cell2, renderItemCell: _l, renderItemLabelCell: _lb} = this;
    const packages = packCount ? `${packCount} ${packType} of` : '';
    const images = signatureImages[releaseType] || index >= 0 ? <img src={index >= 0 ? footers[index].image : signatureImages[releaseType]} style={{width: 180}} alt={''} /> : '';
    const conAddr = consigneeAddr ? consigneeAddr : consigneeAddrLocal;
    const orgBlType = orgBlTypeLabel ? `"${orgBlTypeLabel}"` : '';
    return (
      <div style={{color: 'black', fontSize: 16, pageBreakAfter: 'always', marginTop: '1cm', lineHeight: 1}} key={index}>
        <div className={'flex between'} style={{marginBottom: '10px'}}>
          {title === blPrintTitleOptions[0].value && (<div style={{width: 150}}><img src={coshipLogo} /></div>)}
          {title === blPrintTitleOptions[1].value && (<div style={{width: 220}}><img src={lmsLogo} /></div>)}
          <div style={{alignSelf: 'flex-end', fontSize: 24, fontWeight: 'bold'}}>BILL OF LADING</div>
        </div>
        <table className={'print-table mt-10'} style={{width: '100%'}}>
          <tbody>
          <tr>
            {_cell('SHIPPER / EXPORTER', shipperAddrEng ? shipperAddrEng : shipperAddr, {rowSpan: 2, width: '50%'}, '', '', '', 'value5', 'no-border-l')}
            {_cell('DOCUMENT NUMBER', filingNo || filing_no, {width: '50%'}, 'B/L NO.', blNo, true, 'value5', 'no-border-r')}
          </tr>
          <tr>
            {_cell('EXPORT REFERENCES', exportRefNo, {width: '50%'}, '', '', '', 'value5', 'no-border-r')}
          </tr>
          <tr>
            {_cell('CONSIGNEE', conAddr, {rowSpan: 2, width: '50%'}, '', '', '', 'value5', 'no-border-l')}
            {_cell('FORWARDING AGENT', forwardingAgent, {width: '50%'}, '', '', '', 'value5', 'no-border-r')}
          </tr>
          <tr>
            {_cell('POINT AND COUNTRY OF ORIGIN', '', {width: '50%'}, '', '', '', 'value5', 'no-border-r')}
          </tr>
          <tr>
            {_cell('NORTIFY PARTY', notifyAddr || 'SAME AS CONSIGNEE', {width: '50%'}, '', '', '', 'value3', 'no-border-l')}
            {_cell('TO OBTAIN DELIVERY CONTACT :', masterConsignee, {rowSpan: 2, width: '50%'}, '', '', '', 'value3', 'no-border-r')}
          </tr>
          <tr>
            {_cell('PRE-CARRIAGE BY', '', {width: '50%'}, 'PLACE OF RECEIPT', porLabel, true, 'value', 'no-border-l')}
          </tr>
          <tr>
            {_cell('EXPORTING CARRIER', showVesselPol ? vslVoy : '', {width: '50%'}, 'PORT OF LOADING', showVesselPol ? polFull : '', true, 'value', 'no-border-l')}
            {_cell('ONWARD INLAND ROUTING', onwardRoute, {width: '50%'}, '', '', true, 'value', 'no-border-r')}
          </tr>
          <tr>
            {_cell('PORT OF DISCHARGE', podFull, {width: '50%'}, 'PLACE OF DELIVERY', delivery, true, 'value', 'no-border-l')}
            {_cell('FOR TRANSSHIPMENT TO', forTrTo, {width: '50%'}, 'FINAL DESTINATION', final, true, 'value', 'no-border-r')}
          </tr>
          </tbody>
        </table>
        <div className={'flex center my-1'}>PARTICULARS FURNISHED BY SHIPPER</div>
        <table className={'print-table'} style={{width: '100%', fontSize: '0.9em'}}>
          <tbody>
          <tr>
            <td className={'center no-border-l'} width={'25%'}><div className={'label f-12'}>CONTAINER NO. / SEAL NO.<br/>MARK AND NUMBERS</div></td>
            <td className={'center'} width={'13%'}><div className={'label f-12'}>NO. OF CONT.<br/>OR OTHER PKGS</div></td>
            <td className={'center'} width={'42%'}><div className={'label f-12'}>DESCRIPTION OF PACKAGES AND GOODS</div></td>
            <td className={'center'} width={'10%'}><div className={'label f-12'}>GROSS WEIGHT</div></td>
            <td className={'center no-border-r'} width={'10%'}><div className={'label f-12'}>MEASUREMENT</div></td>
          </tr>
          <tr>
            <td className={'no-border-b no-border-l'} style={{paddingTop: 10}}><div className={'value4'} style={{lineHeight: 1.3, letterSpacing: 0.8}}>{mark}</div></td>
            <td className={'no-border-b'} style={{paddingTop: 10}}><div className={'value4'} style={{lineHeight: 1.3, letterSpacing: 0.8}}>{said}</div></td>
            <td className={'no-border-b'} style={{paddingTop: 10, paddingLeft: 20}}><div className={'value4'} style={{lineHeight: 1.3, letterSpacing: 0.8}}>{descOption}<br/><br/>{desc}</div></td>
            <td className={'no-border-b'} align={'right'} style={{paddingTop: 10}}><div className={'value4'}>{showPkgs ? util.weightForPrint(grossWeightKg, unit) : ''}</div></td>
            <td className={'no-border-b no-border-r'} align={'right'} style={{paddingTop: 10}}><div className={'value4'}>{showPkgs ? util.volumeForPrint(cbm, unit) : ''}</div></td>
          </tr>
          {this.renderBlankLine(parseInt(blankLines.length))}
          <tr>
            {_l('', '', 'no-border-l')}
            {_l()}
            {_l()}
            {_l(showOnboardDate
              ? <div>
                  <div style={{width: 100, paddingBottom: 30, whiteSpace: 'nowrap'}}>ON BOARD DATE:<br/>{util.dateFormatS(onboard || etd).toUpperCase()}</div>
                </div>
              : <div style={{width: 100, paddingBottom: 30}}><br/><br/>{''}</div>)}
            {_l('', '', 'no-border-r')}
          </tr>
          <tr>
            {_l(`${svcTerm1Label} / ${svcTerm2Label}`, 'center', 'no-border-l')}
            {_l()}
            {_l(<div style={{paddingLeft: 20}}>{`"FREIGHT ${freightLabel}"`}</div>)}
            {_l()}
            {_l('', '', 'no-border-r')}
          </tr>
          <tr>
            {_l('', '', 'no-border-l')}
            {_l()}
            {_l(<div style={{paddingTop: 5, paddingLeft: 20}}>{orgBlType}</div>)}
            {_l(<div style={{width: 100, whiteSpace: 'nowrap'}}>-----------------------------</div>)}
            {_l('', '', 'no-border-r')}
          </tr>
          <tr>
            {_lb('Total Number of Containers\nor Packages', 'center', 'no-border-l')}
            {_l()}
            {_l(<div style={{paddingTop: 5, paddingLeft: 20, width: 100, lineHeight: 1.3, whiteSpace: 'pre'}}>{say ? `SAY: ${say}` : ''}</div>)}
            {_l(<div style={{width: 50, height: 10, whiteSpace: 'nowrap'}}>{images}</div>)}
            {_l('', '', 'no-border-r')}
          </tr>
          </tbody>
        </table>
        <table className={'print-table'} style={{width: '100%'}}>
          <tbody>
          <tr style={{height: '50px'}}>
            <td className={'no-border-l center'}><div className={'label'}>FREIGHT AND CHARGES</div></td>
            <td className={'center'}><div className={'label'}>PREPAID</div></td>
            <td className={'center'}><div className={'label'}>COLLECT</div></td>
            <td width={'50%'} rowSpan={2} className={'no-border-r no-border-b'}>
              <div className={'label mb-12'} style={{fontSize: 13}}>The surrender of original order bill of lading property endorsed shall be required before the delivery of the property. Inspection of property covered by this bill of lading will not be permitted unless provided by law or unless permission is endorsed on this original bill of lading or given in writing by the shipper.
In witness whereof the undersigned, signing on behalf of this carrier or agent, has signed three(3) bills of lading, all of the same tenor and date. One of which being accomplished  the others to stand void.
The term and conditions for the order bill of lading under which this shipment is accepted are printed on the back hereof. Note unless otherwise specified the charges listed above do not include duties, taxes, clearance charges, and similar non transportation charges which are for the account of the cargo.</div>
              <hr style={{borderWidth: '1px', borderColor: '#000', marginLeft: -10, width: '103%'}} />
              <div className={'center'} style={{paddingLeft: 120, paddingBottom: 20}}>
                {(title === blPrintTitleOptions[0].value && <div className={'center'} style={{width: 65}}><img src={coshipLogo} /></div>)}
                {(title === blPrintTitleOptions[1].value && <div className={'center'} style={{width: 110}}><img src={lmsLogo} /></div>)}
              </div>
            </td>
          </tr>
          <tr>
            <td valign={'middle'} className={'no-border-l'}><div className={'value mt-36'}>"FREIGHT {freightLabel} AS ARRANGED"</div></td>
            <td>{' '}</td>
            <td>{' '}</td>
          </tr>
          <tr>
            <td rowSpan={2} className={'no-border-l'} style={{verticalAlign: 'middle'}}>TOTAL CHARGES</td>
            <td rowSpan={2}>{' '}</td>
            <td rowSpan={2}>{' '}</td>
            <td width={'50%'} className={'no-border-t no-border-r'} style={{paddingTop: 20}}>
              <div className={'flex'} >
                <div className={'flex-1'}>BY</div>
                <div className={'flex-3'}>{''}</div>
                <div className={'flex-1'}>DATE</div>
                <div className={'flex-2'} style={{textAlign: 'center'}}>{util.dateFormatS(onboard || etd).toUpperCase()}</div>
              </div>
            </td>
          </tr>
          <tr>
            <td width={'50%'} className={'no-border-r'}>
              <div className={'flex bottom'}>
                <div style={{fontSize: 13, paddingTop: 5, paddingBottom: 5}}>{title}<br />{blPrintOpt}</div>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
        {isOriginal && (
          <div className={'mt-2 flex center middle'}>
            {footers[index].label}
          </div>
        )}
      </div>
    );
  }
  renderCell(label, value, tdProps, extraLabel, extraValue, extraHalf, valueClass, tdClass) {
    if (extraLabel) {
      return (
        <td width={'50%'} className={tdClass}>
          <div className={'flex between'}>
            <div className={extraHalf ? 'flex-1' : ''}>
              <div className={'label f-12'}>{label ? label : '\u00a0'}</div>
              <div className={valueClass} style={{whiteSpace: 'pre-line'}}>{value ? value : '\u00a0'}</div>
            </div>
            <div className={extraHalf ? 'flex-1' : ''}>
              <div className={'label f-12 pl-12'} style={{borderLeft: '1px solid #000'}}>{extraLabel ? extraLabel : '\u00a0'}</div>
              <div className={`${valueClass} pl-12`} style={{borderLeft: '1px solid #000', whiteSpace: 'pre-line'}}>{extraValue ? extraValue : '\u00a0'}</div>
            </div>
          </div>
        </td>
      );
    } else {
      return (
        <td {...tdProps} className={tdClass}>
          <div className={'label f-12'}>{label ? label : '\u00a0'}</div>
          <div className={valueClass} style={{whiteSpace: 'pre-line'}}>{value ? value : '\u00a0'}</div>
        </td>
      );
    }
  }
  renderBlankLine(count) {
    const res = [];
    for (let i = 0; i < count; ++i) {
      res.push(
        <tr key={i}>
          <td className={'no-border-b no-border-t no-border-l'}><div className={'value'}>{'\u00a0'}</div></td>
          <td className={'no-border-b no-border-t'}><div className={'value'}>{'\u00a0'}</div></td>
          <td className={'no-border-b no-border-t'}><div className={'value'}>{'\u00a0'}</div></td>
          <td className={'no-border-b no-border-t'}><div className={'value'}>{'\u00a0'}</div></td>
          <td className={'no-border-b no-border-t no-border-r'}><div className={'value'}>{'\u00a0'}</div></td>
        </tr>
      );
    }
    return res;
  }
  renderItemCell(value = ' ', align = undefined, tdClass = '') {
    return <td className={`no-border-b no-border-t ${tdClass}`} align={align}><div className={'value'}>{value}</div></td>;
  }
  renderItemLabelCell(value = ' ', align = undefined, tdClass = '') {
    return <td className={`no-border-b no-border-t ${tdClass}`} align={align} style={{whiteSpace: 'pre-line'}}><div className={'label'}>{value}</div></td>;
  }
  getPrintData() {
    const {printData = {}} = this.props;
    const {blData = {id: 0}, printFormData = {}} = printData;
    if (blData.id <= 0) return null;
    const {jcommon, jcustomer, jvessel, jroute, jshipment, jmark, containers, serverData} = blData
    const {title, blNo, blPrintOpt, releaseType, showVesselPol, showOnboardDate, showPkgs} = printFormData;
    const titleLabel = util.labelFromOptions(title, oemPrintTitleOptions);
    const {shipperAddr = '', shipperAddrEng = '', consigneeAddr = '', consigneeAddrLocal = '', forwardingAgentAddr = '', notifyAddr = '', forwardingAgent = ''} = jcustomer;
    const {bookingNo = '', filingNo = '', bkgNo = bookingNo, b13No = '', exportRefNo = ''} = jcommon;
    const {carrier, vslVoy, onboard, etd, eta} = jvessel;
    const {polLabel, podLabel, porLabel} = jroute;
    const {bladdress = {emptyPickup:  '', forwardAgent: '', partner: '', masterConsignee: '', filing_no: ''}, cutoff = {doc: '', pickupDate: '', portCutoff: '', portOpen: '', rail: '', vgm: ''}, route = {delivery: '', final: '', podFull: '', polFull: '', porFull: ''}} = serverData;
    const {emptyPickup, forwardAgent, partner, masterConsignee, filing_no} = bladdress;
    const {doc, pickupDate, portCutoff, portOpen, rail, vgm} = cutoff;
    const {delivery, final, podFull, polFull, porFull} = route;
    let {freight, orgBlType, svcTerm1, svcTerm2, package: packCount, packageType: packType, grossWeightKg, cbm, sellingRateType} = jshipment;
    const {said, say, desc, mark, descOption = 'SAID TO CONTAIN : ', remark, showAs: unit = 'KG', forTrTo, onwardRoute} = jmark ?? {};
    const freightLabel = util.labelFromOptions(sellingRateType, freightTypeOptions);
    // const orgBlTypeLabel = util.labelFromOptions(orgBlType, orgBlTypeOptions);
    let orgBlTypeLabel = util.labelFromOptions(orgBlType, orgBlTypeOptions);
    const svcTerm1Label = util.svcTermFromOptions(svcTerm1, svcTypeOptions);
    const svcTerm2Label = util.svcTermFromOptions(svcTerm2, svcTypeOptions);
    const numberOfContainers = containers?.length ?? 0;
    const numberOfDesc = ((desc?.match(/\n/g)||[]).length ?? 0) + 1;
    const blankLines = [];
    const blankCount = 11 - numberOfDesc;
    for (let i = 0; i < blankCount; ++i) blankLines.push(i);
    const {user} = this.props;
    const {company, address, email, fax, phone, pic} = user.print;
    const isOriginal = releaseType === 'OR';
    // orgBlTypeLabel = releaseType === 'DR' ? 'EXPRESS B/L' : orgBlTypeLabel;
    return {
      titleLabel, shipperAddr, shipperAddrEng, consigneeAddr, consigneeAddrLocal, forwardingAgentAddr, notifyAddr, exportRefNo, forwardingAgent,
      bkgNo, filingNo, filing_no, b13No,
      carrier, vslVoy, onboard, etd, eta,
      polLabel, podLabel, porLabel,
      said, say, desc, mark, descOption, remark, unit, forTrTo, onwardRoute,
      freightLabel, orgBlTypeLabel, svcTerm1Label, svcTerm2Label, packCount, packType, grossWeightKg, cbm,
      containers,
      blankLines,
      company, address, email, fax, phone, pic,
      title, blNo, blPrintOpt, releaseType, showVesselPol, showOnboardDate, showPkgs, isOriginal,
      emptyPickup, forwardAgent, partner, masterConsignee,
      doc, pickupDate, portOpen, rail, vgm,
      delivery, final, podFull, polFull, porFull,
    };
  }
}

const footers = [
  {label: 'ORIGINAL 3 (FOR SHIPPER)', image: originalUrl},
  {label: 'COPY 8 (FOR AGENT)', image: copyUrl},
  {label: 'ORIGINAL 1 (FOR ISSUING CARRIER)', image: originalUrl},
  {label: 'ORIGINAL 2 (FOR CONSIGNEE)', image: originalUrl},
  {label: 'COPY 4 (DELIVERY RECEIPT)', image: copyUrl},
  {label: 'COPY 5 (EXTRA COPY)', image: copyUrl},
  {label: 'BANK', image: bankUrl},
];

const signatureImages = {
  DR: draftUrl,
  TR: textReleaseUrl,
  SW: seawaybillUrl,
};

export default OEHPrint;
