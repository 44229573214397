/**
 * @flow
 */
import React from 'react';
import Icon from 'mdi-react/SearchIcon';
import {
  useAccountTypeSearch,
  useAirVesselSearch,
  useCustomerSearch, useFlightSearch, useHblSearch, useMBLFilingNoSearch,
  usePartnerSearch, usePortSearch,
  useStaffSearch, useTradeHBLSearch, useVehicleSearch, useVesselSearch, useVslVoySearch
} from './SearchModal';
import type {FormField} from './Form';
import {renderField} from './Form';
import {useTradePartnerSearch} from "./TradePartnerSearchModal";

interface SearchInputProps {
  displayField?: string;
  idField?: string;
  onOK?: (data: any) => void;
  onChange?: () => void;
  className?: string;
  formik: any;
  fields: FormField[];
  errors: any;
  search?: any;
  vertical?: boolean;
  forSmallLabel?: boolean;
  queryText?: string;
  hideIcon?: boolean;
  partnerType?: string;
}

export const SearchInput = (props: SearchInputProps) => {
  const onClick = e => {
    e.preventDefault();
    e.stopPropagation();
    props.search.show(props.formik.values[props.displayField]);
    // props.search.show();
  };
  const field = props.fields.find(i => i.name === props.displayField);
  field.noAutoFill = true;
  // readonly 필드인 경우 아이콘을 보여주지 않음!
  if (field.readonly === true) {
    return (
      <div className={props.className}>
        {renderField(props.formik, props.displayField, props.fields, props.errors)}
      </div>
    );
  }
  field.onEnter = (name, value, e) => {
    e.preventDefault();
    e.stopPropagation();
    props.search.show(value);
  };
  field.onChange = (name, value) => {
    if (props.onChange) {
      props.onChange(name, value);
    } else {
      if (name === props.displayField && props.formik.values[props.idField]) {
        //props.formik.handleChange({target: {name: props.idField, value: undefined}});
        props.formik.setFieldValue(props.idField, undefined);
      }
    }
  };
  field.onBlur = field.doNotClearOnBlur !== true ? (e) => {
    if (!props.formik.values[props.idField]) {
      // 포커스가 블러될때 아이디가 설정되어 있지 않으면 레이블을 삭제
      props.formik.setFieldValue(props.displayField, '');
    }
  } : undefined;
  return (
    <div className={`flex middle ${props.className}`}>
      {renderField(props.formik, props.displayField, props.fields, props.errors)}
      {!props.hideIcon && (
        <div className={`ml-8 ${props.vertical === true ? (props.forSmallLabel === true ? 'mt-16' : '') : 'mb-20'}`}>
          <a href={'/#search'} onClick={onClick}>
            <Icon size={20} />
          </a>
        </div>
        )}

      {props.search.render()}
    </div>
  );
};

export const StaffSearchInput = (props: SearchInputProps) => {
  const onOK = ({id, value}) => defOnOK({id, value}, props);
  const search = useStaffSearch(props.onOK ?? onOK);
  return <SearchInput {...props} search={search} />;
};

export const CustomerSearchInput = (props: SearchInputProps) => {
  const onOK = ({id, value}) => defOnOK({id, value}, props);
  const search = useCustomerSearch(props.onOK ?? onOK);
  return <SearchInput {...props} search={search} />;
};

export const PartnerSearchInput = (props: SearchInputProps) => {
  const onOK = ({id, value}) => defOnOK({id, value}, props);
  const search = usePartnerSearch(props.onOK ?? onOK);
  return <SearchInput {...props} search={search} />;
};

export const AirVesselSearchInput = (props: SearchInputProps) => {
  const onOK = ({id, value}) => defOnOK({id, value}, props);
  const search = useAirVesselSearch(props.onOK ?? onOK);
  return <SearchInput {...props} search={search} />;
};

export const AccountTypeSearchInput = (props: SearchInputProps) => {
  const onOK = ({id, value}) => defOnOK({id, value}, props);
  const search = useAccountTypeSearch(props.onOK ?? onOK);
  return <SearchInput {...props} search={search} />;
};

export const HblSearchInput = (props: SearchInputProps) => {
  const onOK = ({id, value}) => defOnOK({id, value}, props);
  const search = useHblSearch(props.onOK ?? onOK);
  return <SearchInput {...props} search={search} />;
};

export const PortSearchInput = (props: SearchInputProps) => {
  const onOK = ({id, value}) => defOnOK({id, value}, props);
  const search = usePortSearch(props.onOK ?? onOK);
  return <SearchInput {...props} search={search} />;
};

export const VehicleSearchInput = (props: SearchInputProps) => {
  const onOK = ({id, value}) => defOnOK({id, value}, props);
  const search = useVehicleSearch(props.onOK ?? onOK);
  return <SearchInput {...props} search={search} />;
};

let tpSearch;
export const TradePartnerSearchInput = (props: SearchInputProps) => {
  const onOK = ({id, value}) => defOnOK({id, value}, props);
  const search = useTradePartnerSearch(props.onOK ?? onOK, props.partnerType ?? '');
  tpSearch = search;
  return <SearchInput {...props} search={search} />;
};

export function closeTPSearchModal() {
  if (tpSearch) {
    tpSearch.hide();
  }
}

export const VesselSearchInput = (props: SearchInputProps) => {
  const onOK = ({id, value}) => defOnOK({id, value}, props);
  const search = useVesselSearch(props.onOK ?? onOK);
  return <SearchInput {...props} search={search} />;
};

export const VslVoySearchInput = (props: SearchInputProps) => {
  const onOK = ({id, value}) => defOnOK({id, value}, props);
  const search = useVslVoySearch(props.onOK ?? onOK);
  return <SearchInput {...props} search={search} />;
};

export const FlightSearchInput = (props: SearchInputProps) => {
  const onOK = ({id, value}) => defOnOK({id, value}, props);
  const search = useFlightSearch(props.onOK ?? onOK);
  return <SearchInput {...props} search={search} />;
};

export const MBLFilingNoSearchInput = (props: SearchInputProps) => {
  const onOK = ({id, value}) => defOnOK({id, value}, props);
  const search = useMBLFilingNoSearch(props.onOK ?? onOK);
  return <SearchInput {...props} search={search} />;
};

export const TradeHBLSearchInput = (props: SearchInputProps) => {
  const onOK = ({id, value}) => defOnOK({id, value}, props);
  const search = useTradeHBLSearch(props.onOK ?? onOK, props.queryText);
  return <SearchInput {...props} search={search} />;
};

function defOnOK({id, value}, props: SearchInputProps) {
  props.formik.handleChange({target: {name: props.displayField, value}});
  props.formik.handleChange({target: {name: props.idField, value: id}});
}
